<template>
  <div class="login_pane">
    <h2 class="title">系统登录使用</h2>
    <div class="login-box">
      <img src="@/assets/login-icon.png" alt="">
      <div class="login-box-btn" style="position: absolute; right: 412px; top: 80px">
        <div>
          <p>物流企业登录入口</p>
          <p>业务+融资管理一体化操作</p>
        </div>
        <div class="btn" style="background: #2D68FF" @click="goLogin(1)">物流企业登录</div>
      </div>
      <div class="login-box-btn" style="position: absolute; right: 38px; top: 80px">
        <div>
          <p>金融机构登录入口</p>
          <p>金融机构授信，放款审批全流程智能操作平台</p>
        </div>
        <div class="btn" style="background: #EB6100" @click="goLogin(2)">金融机构登录</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'loginPane',
    emits: ['close'],
    setup(props, { emit }) {
      const goLogin = (type) => {
        if(type === 1) {
          window.open('https://client.56bank.cn/', '_blank')
        } else {
          window.open(`http://admin.56bank.cn/`, '_blank')
        }
      }
      return {
        goLogin
      }
    }
  };
</script>

<style scoped lang="less">
  .login_pane {
    margin-bottom: 100px;
    overflow-x: auto;
    .title {
      margin-top: 100px;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: #595757;
      line-height: 30px;
    }
  }
  .login-box {
    width: 1349px;
    height: 471px;
    background: url('@/assets/login-box.png') no-repeat center;
    background-size: 100% 100%;
    margin: 65px auto;
    position: relative;
    img {
      width: 522px;
      height: 301px;
      position: absolute;
      left: 28px;
      top: 118px;
    }
    &-btn {
      width: 338px;
      height: 316px;
      background: #FFFFFF;
      box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.13);
      border-radius: 30px;
      padding: 60px 56px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      p:nth-of-type(1) {
        font-size: 24px;
        font-weight: bold;
        color: #595757;
        line-height: 30px;
        text-align: center;
        margin-bottom: 25px;
      }
      p:nth-of-type(2) {
        font-size: 19px;
        color: #595757;
        line-height: 30px;
      }
      .btn {
        width: 172px;
        height: 45px;
        border-radius: 23px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 45px;
        cursor: pointer;
        user-select: none;
      }
    }
  }
</style>
