<template>
  <div class="m">
    <div class="header" @click="goHome">
      <img alt="" class="header-logo" src="@/assets/logo.png" />
      <svg @click="showLogin = !showLogin" class="extend-icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M128 192h768v128H128V192zm0 256h512v128H128V448zm0 256h768v128H128V704zm576-352 192 160-192 128V352z"></path></svg>
    </div>
    <component :is="component" class="components" @changeNav="changeRouter" :activeIndex="activeIndex" @close="$emit('close')"></component>
    <!-- Copyright -->
    <div class="footer-box">
      <div class="footer-box-top">
        <div class="lx-2">
          <p>联系我们</p>
          <p><span class="lf-bt">联系电话：</span> 010-64933553</p>
          <p><span class="lf-bt">公司地址：</span> 北京市朝阳区北辰东路8号院1号楼（北辰时代大厦）</p>
          <p style="margin-left: 1.1rem">20层2008号</p>
          <p style="margin-left: 1.1rem">重庆市江北区庆云路16号国金中心T2塔楼32层</p>
        </div>
      </div>
      <div class="footer-box-bottom">
        <a class="footer-box-bottom-txt" href="https://beian.miit.gov.cn/" target="_blank">Copyright©北京弘运保科技有限公司 京ICP备2020048651号-4</a>
      </div>
    </div>
    <div class="login-panel" v-if="showLogin">
      <!-- <svg @click="showLogin = !showLogin" showLogin class="close-icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248L466.752 512z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z"></path></svg> -->
      <div class="login-panel-box" style="margin-top: 1rem">
        <img alt="" class="header-logo" src="@/assets/m/login1.png" />
        <div class="right">
          <p>物流企业登录入口</p>
          <p>业务+融资管理一体化操作</p>
          <div class="login-btn" @click="goLogin(1)">登录</div>
        </div>
      </div>
      <div class="login-panel-box">
        <img alt="" class="header-logo" src="@/assets/m/login2.png" />
        <div class="right">
          <p>金融机构登录入口</p>
          <p>金融机构授信，放款审批全流程智能操作平台</p>
          <div class="login-btn" @click="goLogin(2)">登录</div>
        </div>
      </div>
    </div>
    <div class="allScreen-mask" v-if="allScreen">
      <svg @click="closeALlScreen" showLogin class="close-icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248L466.752 512z"></path><path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z"></path></svg>
      <img class="allScreen-mask-icon" src="https://admin.56bank.cn/api/hittec-basic/ossFile/download?path=common/2023/09/14/8ed3dfc3-8410-46e5-8e85-4f55794ad223.png&name=mask" alt="">
      <a href="https://baijiahao.baidu.com/s?id=1767525892424956519&wfr=spider&for=pc" style="">如何打开横屏？</a>
    </div>
  </div>
</template>

<script>
  import { defineComponent, reactive, ref, unref, toRaw } from 'vue';
  import home from './home.vue';
  import service from '../web/temporary/service.vue';
  import about from '../web/temporary/about.vue';
  import loginPane from '../web/temporary/loginPane.vue';

  export default defineComponent({
    name: 'Temporary',
    components: { home },
    setup(props, { emit }) {
      const component = ref(home);
      const activeIndex = ref(0);
      const routers = ref([
        { name: '首页', components: home },
        { name: '系统服务', components: service },
        { name: '关于我们', components: about },
        { name: '', components: home },
        { name: '', components: loginPane },
      ]);
      const changeRouter = (index) => {
        activeIndex.value = index;
        component.value = routers.value[activeIndex.value].components;
      };
      const goHome = () => {
        document.body.scrollTop = 0;
        activeIndex.value = 0;
      }
      const loginType = ref(1);
      const showLogin = ref(true);
      const allScreen = ref(false);

      const goLogin = (type) => {
        loginType.value = type;
        if(window.orientation === 0 && !window.localStorage.getItem('allScreen')) {
          allScreen.value = true;
        }else {
          jumpPage()
        }
      }
      const closeALlScreen = () => {
        window.localStorage.setItem('allScreen', true);
        allScreen.value = !allScreen.value;
        jumpPage();
      }

      const jumpPage = (url) => {
        showLogin.value = !showLogin.value;
        if(loginType.value === 1) {
          diffJump('https://client.56bank.cn/')
        } else {
          diffJump('https://admin.56bank.cn/')
        }
      }

      const diffJump = (url) => {
        let system = navigator.userAgent
        let isiOS = !!system.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if(isiOS) {
          window.location.href = url
        } else {
          window.open(url, '_blank')
        }
      } 

      window.addEventListener("orientationchange", function() {
        if(window.orientation === 90){
          if(allScreen.value) closeALlScreen()
        }
      }, false);

      return {
        activeIndex,
        routers,
        changeRouter,
        component,
        goHome,
        showLogin,
        goLogin,
        allScreen,
        closeALlScreen
      };
    },
  });
</script>

<style lang="less" scoped>
  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; 
  }
  .container {
    height: 100vh;
    overflow-y: scroll;
  }

  .header {
    display: flex;
    height: 1.13rem;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    position: relative;
    .extend-icon {
      width: 0.4rem;
      color: #474aff;
      position: absolute;
      right: 0.3rem;
      top: 0.3rem;
    }
    &-logo {
      width: 2rem;
    }
  }

  .components {
    padding-bottom: 50px;
  }

  .footer-box {
    width: 100vw;
    height: 3rem;
    background-color: #E5E5E5;
    color: #595757;
    margin-top: 0.62rem;
    padding: 0.3rem 0.52rem;
    font-size: 0.2rem;
    line-height: 0.4rem;
    position: relative;
    &-top {
      position: relative;
      .lx-1 {
        border-bottom: 1px solid #B5B5B5;
        padding-bottom: 22px;
        .logo {
          width: 349px;
        }
      }
      .lx-2 {
        p:nth-of-type(1) {
          font-weight: 600;
        }
        .lf-bt {
          display: inline-block;
          width: 1.1rem;
        }
      }
    }
    &-bottom {
      width: 100%;
      text-align: center;
      position: absolute;
      right: 0;
      bottom: 0.2rem;
      &-txt {
        color: #3E3A39;
        text-decoration: none;
        font-size: 0.2rem;
        transform: scale(0.8);
        display: block;
      }
    }
  }
  .login-panel {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 10;
    overflow: auto;
    .close-icon {
      width: 0.5rem;
      color: #474aff;
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
    }
    &-box {
      width: 90%;
      background: #E5F5FD;
      border-radius: 0.2rem;
      font-size: 10px;
      margin: 0.5rem auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0.2rem;
      img {
        width: 3rem;
        height: 1.9rem;
      }
      .right {
        p:nth-of-type(1) {
          width: 3rem;
          text-align: center;
          font-size: 0.25rem;
          font-weight: bold;
          color: #1E78BE;
        }
        p:nth-of-type(2) {
          font-size: 0.22rem;
          text-align: center;
          color: #616161;
          width: 3rem;
          margin: 0.1rem;
          line-height: 0.3rem;;
        }
        .login-btn {
          width: 1.4rem;
          height: 0.4rem;
          background: #006CBD;
          border-radius: 0.1rem;
          color: #fff;
          text-align: center;
          line-height: 0.4rem;
          font-size: 0.2rem;
          margin: 0.15rem auto;
        }
      }
    }
  }
  .allScreen-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .close-icon {
      width: 0.5rem;
      color: #FFF;
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
    }
  }
  .allScreen-mask .allScreen-mask-icon {
    width: 70%;
    margin-top: -3.5rem;
  }
  .allScreen-mask a {
    color: #388ccc;
    font-size: 30px;
    font-size: 0.2rem;
    margin-top: 0.1rem;
  }
</style>
<style lang="less">
  @media (max-width: 1000px) {
    .el-message {
      font-size: 0.3rem;
    }
  }
</style>
