<template>
  <div>
    <div class="screen-item screen-one">
      <img class="banner" src="@/assets/bannerBig.jpg" alt="">
      <div class="title">
        <p>建立企业的数据信用体系</p>
        <p>打造专业的金融科技平台</p>
      </div>
      <div class="count-box" v-if="activeIndex == 0">
        <div class="count">
          <img src="@/assets/yuan1.png" alt="">
          <p>{{number1}}</p>
          <p>累计服务企业客户</p>
        </div>
        <div class="count">
          <img src="@/assets/yuan2.png" alt="">
          <p>{{number2}}</p>
          <p>客户累计获得授信</p>
        </div>
        <div class="count">
          <img src="@/assets/yuan3.png" alt="">
          <p>{{number3}}</p>
          <p>客户累计完成融资</p>
        </div>
        <div class="count">
          <img src="@/assets/yuan4.png" alt="">
          <p>{{number4}}</p>
          <p>客户累计获得融资</p>
        </div>
      </div>
    </div>

    <div v-if="activeIndex == 0" class="screen-item screen-two">
      <h2>为什么选择真保</h2>
			<h3>真保四大优势</h3>
      <div class="screen-two-box">
        <div class="why-box scale-2">
          <a class="s2-i1">
            <img src="@/assets/p2bg1.png" alt="">
            <h5><i></i>成本低，额度高</h5>
            <p>年化利率低于市场同类金融产品的平均利率水平<br>且以实际用款天数计算，公平合理</p>
          </a>
          <a class="s2-i2">
            <img src="@/assets/p2bg2.png" alt="">
            <h5><i></i>免抵押，免担保</h5>
            <p>凭借有效合同、真实运单，即可融资<br>无需资产抵押，操作门槛低</p>
          </a>
          <a class="s2-i3">
            <img src="@/assets/p2bg3.png" alt="">
            <h5><i></i>随借随还，灵活用款</h5>
            <p>运单融资，项目合作，股权合作<br>合同换签多种产品解决您的资金需求</p>
          </a>
          <a class="s2-i4">
            <img src="@/assets/p2bg4.png" alt="">
            <h5><i></i>线上操作，便捷高效</h5>
            <p>额度循环使用，可根据业务需要随借随还<br>资金用途不作特别限制</p>
          </a>
        </div>
        <div class="computer">
          <p>注册真保账号，提交融资申请</p>
          <img src="@/assets/computer.png" alt="">
          <div class="btn common-btn" @click="goCheck">立即提交</div>
        </div>
      </div>
    </div>

    <!-- <div v-if="activeIndex == 0" class="screen-item screen-three">
      <h2>真保产品服务</h2>
      <div class="screen-three-box">
        <div
          v-for="(item,index) in productData"
          :key="index"
          :class="{active: activeBox === index}"
          class="screen-three-box_img"
          @mouseenter="activeBox = index"
          @click="goDetails(index)"
        >
          <div class="bottom-box">
            <p>{{item.p1}}</p>
            <p>{{item.p2}}</p>
          </div>
        </div>
      </div>
    </div> -->

    <div v-if="activeIndex == 3" class="screen-item screen-five">
      <h2>什么企业可以申请{{productData[productIndex].p1}}</h2>
      <div class="screen-five-content">
        <div class="img-box-left flex-center">
          <img :src="productData[productIndex].banner" alt="">
        </div>
        <div class="msg-right">
          <div class="msg-right-item">
            <div class="icon flex-center">
              <img src="@/assets/p5i1.png" alt="">
            </div>
            <p>{{productData[productIndex].t1}}</p>
          </div>
          <div class="msg-right-item">
            <div class="icon flex-center">
              <img src="@/assets/p5i2.png" alt="">
            </div>
            <p>{{productData[productIndex].t2}}</p>
          </div>
          <div class="msg-right-item">
            <div class="icon flex-center">
              <img src="@/assets/p5i3.png" alt="">
            </div>
            <p>{{productData[productIndex].t3}}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeIndex == 3" class="screen-item screen-six">
      <h2>产品要素</h2>
      <div class="screen-six-content">
        <div
          class="img-box"
          v-for="(item,index) in productData[productIndex].factor"
          :key="index"
        >
          <img :src="item.img" alt="">
          <p class="title">{{item.title}}</p>
          <p class="msg">{{item.msg}}</p>
        </div>
      </div>
    </div>

    <div v-if="activeIndex == 3" class="screen-item screen-seven">
      <h2>立即获得资金支持</h2>
      <div class="screen-seven-support">
        <p class="title">真保，专注物流金融，助力物流企业快速获得资金支持</p>
        <div class="input-box">
          <el-input
            placeholder="您的姓名"
            v-model="formVal.name"
            style="width: 136px"
            >
            <template #prefix><i style="color: red">*</i></template>
          </el-input>
          <el-input
            placeholder="您的手机号"
            v-model="formVal.phone"
            style="width: 152px"
            >
            <template #prefix><i style="color: red">*</i></template>
          </el-input>
          <el-input
            placeholder="您的公司名称"
            v-model="formVal.company"
            style="width: 152px"
            >
            <template #prefix><i style="color: red">*</i></template>
          </el-input>
          <el-input
            placeholder="请描述您的需求，我们将尽快与您联系"
            v-model="formVal.remarks"
            style="width: 350px"
            >
            <template #prefix><i style="color: red">*</i></template>
          </el-input>
          <div class="btn btn-confirm" @click="confirmClue">立即获得</div>
        </div>
        <img src="@/assets/car.png" alt="">
      </div>
    </div>

    <div class="screen-item screen-four">
      <h2>有物流的地方就有真保，真保已遍布全国20多个省市地区</h2>
      <h3>全国客服热线：010-64933553</h3>
      <div class="map">
        <img class="map-image" src="@/assets/map.png" alt="">
        <div class="address-icons">
          <div class="address-icons_item" v-for="(item,index) in addressData" :key="index">
            <p>{{item}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 试算 -->
  <el-dialog v-model="qrcodeVis" title="试试我能贷多少" cancelButtonText="" confirmButtonText="">
    <div class="qrcode-wrap">
      <h2>扫码联系您的专属客户经理，帮助您免费进行额度测算！</h2>
      <div class="qrcode-wrap-box">
        <div class="qrcodes">
          <img src="@/assets/1v1.png" alt="">
          <div class="txt">
            <p>周经理</p>
            <p>电话：153 3456 7351</p>
          </div>
        </div>
        <!-- <div class="qrcodes">
          <img src="@/assets/yan.png" alt="">
          <div class="txt">
            <p>严经理</p>
            <p>电话：177 8364 2912</p>
          </div>
        </div> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { defineComponent, reactive, toRefs, ref, unref, toRaw, onMounted } from 'vue';
  import img1 from '@/assets/p5c1.png';
  import img2 from '@/assets/p5c2.png';
  import img3 from '@/assets/p5c3.png';
  import img4 from '@/assets/p5c4.png';
  import img5 from '@/assets/p5c5.png';
  import img6 from '@/assets/p5c6.png';
  import img7 from '@/assets/p5c7.png';
  import img8 from '@/assets/p5c8.png';
  import img9 from '@/assets/p5c9.png';
  import img10 from '@/assets/p2bg5.png';
  import img11 from '@/assets/p2bg6.png';

  export default defineComponent({
    name: 'home',
    emits: ['changeNav', 'contactUs'],
    components: {},
    props: {
      activeIndex: Number
    },
    setup(props, { emit }) {
      const state = reactive({
        number1: '100+',
        number2: '¥1000+',
        number3: '3000笔',
        number4: '¥1000000000',
        addressData: ['吉林', '辽宁', '北京', '内蒙古', '河北', '山东', '陕西', '河南', '江苏', '上海', '浙江', '湖北', '四川', '重庆', '江西', '广东', '新疆'],
        productData: [
          {
            p1: '运单融资',
            p2: '暗保理 到账快 随借随还',
            banner: img10,
            t1: '企业的上游货主：国企央企及其子公司；\n上市公司及其3级以内控股子公司；AA发债企业及其3级以内子公司。',
            t2: '企业自身：经营正常，负债合理，征信，无严重污点等。',
            t3: '货主系统：货主需有物流系统，且企业使用该物流系统进行运输任务。',
            factor: [
              {img: img1, title: '保理类型', msg: '暗保理'},
              {img: img2, title: '融资人', msg: '合同物流企业'},
              {img: img3, title: '授信额度', msg: '参考运费规模和账期 确定，单个客户最高 授信1500万'},
              {img: img4, title: '融资期限', msg: '1年'},
              {img: img5, title: '融资比例', msg: '当期应收款的70%-80%'},
              {img: img6, title: '融资成本', msg: '11%左右'},
              {img: img7, title: '放款时点', msg: '运单完成即可申请放款'},
              {img: img9, title: '增信措施', msg: '法定代表人及大股东夫妇担保'},
            ]
          },
          {
            p1: '项目合作',
            p2: '要求低 使用便捷 个性定制',
            banner: img10,
            t1: '企业的上游货主：国企央企及其子公司；\n上市公司及其3级以内控股子公司；AA发债企业及其3级以内子公司。',
            t2: '企业自身：经营正常，负债合理，征信，无严重污点等。',
            t3: '货主系统：无特殊要求。',
            factor: [
              {img: img1, title: '保理类型', msg: '暗保理'},
              {img: img2, title: '融资人', msg: '合同物流企业'},
              {img: img3, title: '授信额度', msg: '依据项目规模'},
              {img: img4, title: '融资期限', msg: '承运合同期限'},
              {img: img5, title: '融资比例', msg: '当期应收款的90%'},
              {img: img6, title: '融资成本', msg: '10%左右'},
              {img: img7, title: '放款时点', msg: '运单完成即可申请放款'},
              {img: img9, title: '增信措施', msg: '法定代表人及大股东夫妇担保'},
            ]
          },
          {
            p1: '股权合作',
            p2: '深度绑定 风险共担',
            banner: img11,
            t1: '深度合作-历史经营稳健。',
            t2: '优质承运项目。',
            t3: '运费支持力度超过90%。',
            factor: [
              {img: img1, title: '金融产品', msg: '股权合作'},
              {img: img2, title: '融资人', msg: '物流企业，无其他特殊要求'},
              {img: img3, title: '授信额度', msg: '依据项目规模'},
              {img: img5, title: '融资比例', msg: '超过当期应收款的90%'},
              {img: img8, title: '产品特点', msg: '风险共担、收益共享'},
            ]
          },
          {
            p1: '合同换签',
            p2: '灵活高效 额度高 成本低',
            banner: img11,
            t1: '供应链金融-需优质承运项目。',
            t2: '运营你负责，资金我来找。',
            t3: '运费支持力度超过95%。',
            factor: [
              {img: img1, title: '金融产品', msg: '合同换签'},
              {img: img2, title: '融资人', msg: '供应链金融'},
              {img: img3, title: '授信额度', msg: '依据项目规模'},
              {img: img5, title: '融资比例', msg: '超过当期应收款的95%'},
              {img: img8, title: '产品特点', msg: '背靠背协议'},
            ]
          }
        ],
        isInit: true,
        activeBox: 0,
        productIndex: 0,
      })

      const numberAni = ()=> {
        numBounce(88, 100, 1, '+', 1);
        numBounce(2.5, 5.5, 2, '亿', 1);
        numBounce(2990, 3000, 3, '笔', 1);
        numBounce(7, 10, 4, '亿', 1);
      }

      const numBounce = (initialNum, finishNum, index, otherText, frequency, time) => {
        var initial = initialNum;
        var intervalTime = 100
        if (time) {
          intervalTime = time;
        }
        var statisticsNum = setInterval(function() {
          state['number'+index] = initial + otherText;
          initial += frequency ? frequency : 1
          if (initial > finishNum) {
            clearInterval(statisticsNum)
          };
        }, intervalTime);
      }

      const goDetails = (type) => {
        document.body.scrollTop = 0;
        emit('changeNav', 3);
        state.productIndex = type
      }

      const goCheck = () => {
        emit('contactUs')
      }

      onMounted(()=> {
        numberAni();
      })

      return {
        ...toRefs(state),
        goDetails,
        goCheck
      }
    },
  });
</script>

<style lang="less" scoped>
  .screen-one {
    scroll-snap-align: end!important;;
    position: relative;
    .banner {
      width: 100%;
      min-height: 300px;
    }
    // height: 609px;
    // background: url('@/assets/banner.png')
    //   no-repeat;
    // background-size: 100%;
    // box-sizing: border-box;
    .title {
      position: absolute;
      left: 20.5%;
      top: 16%;
    }
    h1 {
      font-size: 69px;
      font-weight: bold;
      color: #F08300;
      line-height: 120px;
      position: relative;
      left: 35px;
      margin-bottom: 10px;
      &::before {
        position: absolute;
        top: 30px;
        left: -35px;
        width: 12px;
        height: 65px;
        background: #F08300;
        content: ' ';
      }
    }
    p {
      font-size: 32px;
      font-weight: 400;
      color: #595757;
      line-height: 58px;
    }
  }

  .count-box {
    width: 66vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
    // margin-left: -33vw;
    // margin-top: 80px;
    // position: absolute;
    left: 50%;
    .count {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      img {
        width: 91px;
        height: 91px;
      }
      p:nth-of-type(1) {
        font-size: 30px;
        line-height: 35px;
        font-weight: 400;
        color: #898989;
        margin-top: 24px;
        text-align: center;
      }
      p:nth-of-type(2) {
        font-size: 18px;
        line-height: 23px;
        font-weight: 400;
        color: #898989;
        margin-top: 8px;
      }
    }
  }

 .screen-two {
    width: 100%;
    margin-top: 150px;
    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .computer {
      width: 300px;
      height: 450px;
      background: #FFFFFF;
      box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.28);
      border-radius: 10px;
      padding: 40px 30px;
      p {
        font-size: 17px;
        font-weight: 500;
        color: #595757;
        line-height: 44px;
        text-align: center;
        border-bottom: 1px solid #000;
      }
      img {
        display: block;
        width: 125px;
        height: 139px;
        margin: 50px auto;
      }
      .btn {
        width: 121px;
        height: 42px;
        background: #2668FF;
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 200;
        color: #FFFFFF;
        line-height: 42px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .screen-two .why-box {
    box-shadow: 0px 0px 30px #dbdbdb;
    border-radius: 10px;
    overflow: hidden;
    width: 895px;
    margin: 40px 146px 40px 0;
  }

  body .screen-two .why-box a {
    width: 445px;
    height: 268px;
    float: left;
    position: relative;
  }
  body .screen-two .why-box {
    a:nth-of-type(1) {
      margin: 0 4px 4px 0;
      h5 {
        padding: 5px 0px 5px 50px;
      }
      h5 i {
        position: absolute;
        width: 34px;
        height: 34px;
        background: url('@/assets/p2i1.png') no-repeat;
        background-size: 100%;
        left: 10px;
        top: 7px;
      }
    }
    a:nth-of-type(2) {
      margin: 0 0 4px 0;
      h5 {
        padding: 5px 0px 5px 50px;
      }
      h5 i {
        position: absolute;
        width: 34px;
        height: 34px;
        background: url('@/assets/p2i2.png') no-repeat;
        background-size: 100%;
        left: 10px;
        top: 7px;
      }
    }
    a:nth-of-type(3) {
      margin: 0 4px 0 0;
      h5 {
        width: 190px;
        margin-left: -90px;
      }
      h5 i {
        position: absolute;
        width: 34px;
        height: 34px;
        background: url('@/assets/p2i4.png') no-repeat;
        background-size: 100%;
        left: 6px;
        top: 7px;
      }
    }
    a:nth-of-type(4) {
      h5 {
        width: 190px;
        margin-left: -90px;
      }
      h5 i {
        position: absolute;
        width: 34px;
        height: 34px;
        background: url('@/assets/p2i3.png') no-repeat;
        background-size: 100%;
        left: 6px;
        top: 7px;
      }
    }
  }

  .screen-two .why-box a img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  .screen-two .why-box a h5 {
    font-size: 18px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -100px;
    width: 170px;
    height: 44px;
    display: inline-block;
    line-height: 40px;
    background-color: #fff;
    z-index: 1;
    border-radius: 10px 10px 0px 0px;
    padding: 5px 0px 5px 45px;
    color: #595757;
    font-size: 15px;
  }

  .screen-two .why-box p {
    display: none;
  }
  .why-box a:hover {
    background: linear-gradient(220.55deg, #DDE4FF 0%, #8DA2EE 100%);
  }
  .screen-two .why-box a:hover img {
    display: none;
}

  .screen-two .why-box a:hover h5 {
      width: 100%!important;
      margin-left: 0 !important;
      padding: 0 !important;
      position: relative;
      left: inherit;
      bottom: inherit;
      margin: 20% 0 0;
      border-radius: 0;
      font-size: 28px;
      color: #fff;
      background: transparent;
      text-align: center;
  }

  .screen-two .why-box a:hover h5 i {
      display: none;
  }

  .screen-two .why-box a:hover h5:before,.screen-two .why-box a:hover h5:after{
      display: none;
  }

  .screen-two .why-box a:hover p {
      display: block;
      font-size: 18px;
      padding: 10px;
      line-height: 175%;
      color: #fff;
      text-align: center;
  }

  .screen-three {
    margin-top: 150px;
    width: 100%;
    h2 {
      font-size: 35px;
      font-weight: bold;
      color: #595757;
      line-height: 60px;
      text-align: center;
      margin-bottom: 14px;
    }
    &-box {
      width: 1349px;
      margin: 65px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &_img {
        width: 293px;
        height: 534px;
        background: rgba(0,0,0,0.7);
        margin-right: 32px;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .bottom-box {
          background: #1657CE;
          opacity: 0.66;
          color: #fff;
          text-align: center;
          padding: 20px 60px;
          box-sizing: border-box;
          transform: translateY(15%);
          transition: all .5s;
          p:nth-of-type(1) {
            font-size: 27px;
            font-weight: bold;
          }
          p:nth-of-type(2) {
            font-size: 18px;
            font-weight: bold;
            margin-top: 20px;
            display: none;
          }
        }
      }
      .active {
        width: 391px;
        .bottom-box {
          transform: translateY(0);
          p:nth-of-type(2) {
            display: block;
          }
        }
      }
      &_img:nth-of-type(1) {
        background: url('@/assets/car1.jpg') no-repeat center;
        background-size: cover;
      }
      &_img:nth-of-type(2) {
        background: url('@/assets/car2.jpg') no-repeat center;
        background-size: cover;
      }
      &_img:nth-of-type(3) {
        background: url('@/assets/car3.jpg') no-repeat center;
        background-size: cover;
      }
      &_img:nth-of-type(4) {
        background: url('@/assets/car4.jpg') no-repeat center;
        background-size: cover;
        margin-right: 0;
      }
    }
  }

  .screen-four {
    margin-top: 150px;
    .map {
      width: 700px;
      margin: 0 auto;
      text-align: center;
      position: relative;
      .map-image {
        width: 100%;
      }
      .address-icons {
        &_item {
          position: absolute;
          width: 60px;
          height: 60px;
          background: url('@/assets/map_pop.png') no-repeat center;
          background-size: 100% 100%;
          font-size: 15px;
          text-align: left;
          line-height: 50px;
          color: #fff;
          animation: pulse 1.5s linear 2s infinite forwards;
          p {
            width: 45px;
            height: 40px;
            text-align: center;
          }
        }
        &_item:nth-of-type(1) {
          top: 106px;
          right: 102px;
          // -webkit-animation: bounce 2s .3s infinite both;
        }
        &_item:nth-of-type(2) {
          top: 170px;
          right: 110px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
        &_item:nth-of-type(3) {
          top: 177px;
          right: 180px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
        &_item:nth-of-type(4) {
          top: 170px;
          right: 300px;
          // -webkit-animation: bounce 2.2s .1s infinite both;
        }
        &_item:nth-of-type(5) {
          top: 217px;
          right: 180px;
          // -webkit-animation: bounce 3s 0.5s infinite both;
        }
        &_item:nth-of-type(6) {
          top: 288px;
          right: 120px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
        &_item:nth-of-type(7) {
          top: 270px;
          right: 309px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
        &_item:nth-of-type(8) {
          top: 280px;
          right: 218px;
          // -webkit-animation: bounce 2.4s .2s infinite both;
        }
        &_item:nth-of-type(9) {
          top: 346px;
          right: 100px;
          // -webkit-animation: bounce 2.2s .6s infinite both;
        }
        &_item:nth-of-type(10) {
          top: 346px;
          right: 50px;
          // -webkit-animation: bounce 2.1s .4s infinite both;
        }
        &_item:nth-of-type(11) {
          bottom: 114px;
          right: 100px;
          // -webkit-animation: bounce 2.6s .2s infinite both;
        }
        &_item:nth-of-type(12) {
          top: 325px;
          right: 250px;
          // -webkit-animation: bounce 2.2s .1s infinite both;
        }
        &_item:nth-of-type(13) {
          top: 366px;
          right: 310px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
        &_item:nth-of-type(14) {
          top: 366px;
          right: 270px;
          // -webkit-animation: bounce 2.3s .5s infinite both;
        }
        &_item:nth-of-type(15) {
          bottom: 100px;
          right: 200px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
        &_item:nth-of-type(16) {
          bottom: 40px;
          right: 186px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
        &_item:nth-of-type(17) {
          top: 140px;
          left: 120px;
          // -webkit-animation: bounce 2.1s .2s infinite both;
        }
      }
    }
  }

  .screen-five {
    width: 1349px;
    margin: 115px auto;
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img-box-left {
        width: 564px;
        height: 366px;
        border-radius: 20px;
        background: linear-gradient(0deg, #B9E3F8 0%, #DCECF9 100%);
        margin: 78px 0;
        img {
          width: 462px;
          height: 346px;
        }
      }
      .msg-right {
        width: 700px;
        height: 300px;
        margin-left: 61px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: flex-start;
        &-item {
          display: flex;
          align-items: center;
          .icon {
            width: 64px;
            height: 64px;
            border: 2px solid #B5B5B5;
            border-radius: 50%;
            img {
              width: 30px;
              height: 30px;
            }
          }
          p {
            font-size: 20px;
            font-weight: 400;
            color: #595757;
            line-height: 26px;
            margin-left: 29px;
            white-space: pre;
          }
        }
      }
    }
  }

  .screen-six {
    width: 1349px;
    margin: 115px auto;
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .img-box {
        width: 176px;
        height: 244px;
        border-radius: 25px;
        background: linear-gradient(0deg, #B9E3F8 0%, #bed9ff 100%);
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin: 46px 28px 0 28px;
        img {
          width: 61px;
          height: 69px;
        }
        .title {
          font-size: 20px;
          font-weight: 500;
          color: #1C43C5;
          line-height: 26px;
          text-align: center;
          margin: 20px 0;
        }
        .msg {
          font-size: 14px;
          font-weight: 300;
          font-family: Source Han Sans CN;
          color: #3E3A39;
          line-height: 22px;
          width: 100%;
          height: 100px;
          text-align: center;
        }
      }
    }
  }

  .screen-item {
    scroll-snap-align: center;
    // @media (min-width: @screen-xxxl-min) {
    //   background-color: red;
    // }
    // @media (min-width: @screen-xl-max) and (max-width: @screen-xxl-max) {
    //   background-color: blue;
    // }
    // @media (min-width: @screen-lg-max) and (max-width: @screen-xl-max) {
    //   background-color: yellow;
    // }
    // @media (min-width: @screen-md-max) and (max-width: @screen-lg-max) {
    //   background-color: #000;
    // }
    // @media (min-width: @screen-sm-max) and (max-width: @screen-md-max) {
    //   background-color: pink;
    // }
    h2 {
      font-size: 35px;
      font-weight: bold;
      color: #595757;
      line-height: 60px;
      text-align: center;
    }
    h3 {
      font-size: 28px;
      font-weight: 400;
      color: #595757;
      line-height: 60px;
      text-align: center;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // qrcode
  .qrcode-wrap {
    text-align: center;
    &-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 30px;
    }
    .qrcodes {
      display: flex;
      align-items: center;
      img {
        width: 160px;
        height: 160px;
      }
      .txt {
        text-align: left;
        line-height: 25px;
        margin-left: 15px;
      }
    }
  }

  @-webkit-keyframes pulse{
    0%{-webkit-transform:scale(0.9)}
    50%{-webkit-transform:scale(0.8)}
    100%{-webkit-transform:scale(0.9)}
  }
  @keyframes pulse{
    0%{-webkit-transform:scale(0.9)}
    50%{-webkit-transform:scale(0.8)}
    100%{-webkit-transform:scale(0.9)}
  }
  @screen-lg-max: 1200px;
  @screen-xl-max: 1600px;

  @media (min-width: @screen-lg-max) and (max-width: @screen-xl-max) {
    .screen-one {
      .count-box {
        margin: 30px auto;
        .count {
          img {
            width: 70px;
            height: 70px;
          }
          p:nth-of-type(1) {
            font-size: 23px;
            line-height: 25px;
            margin-top: 10px;
          }
        }
      }
    }
    .screen-two,.screen-three,.screen-four {
      scale: 0.9;
    }
  }
</style>
