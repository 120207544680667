import { createRouter, createWebHashHistory } from 'vue-router';
import homePage_pc from '@/components/web/homePage.vue';
import homePage_m from '@/components/mobile/homePage.vue';

const routes = [
  {
    path: '/m',
    name: 'homePage_m',
    meta: {
      title: '真保｜物流金融服务平台',
      data: {
        name: 'viewport',
        content: 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'
      }
    },
    component: homePage_m
  },
  {
    path: '/pc',
    name: 'homePage_pc',
    meta: {
      title: '真保｜物流金融服务平台',
    },
    component: homePage_pc
  }
]
const routerConfig = createRouter({history: createWebHashHistory(), routes})

const writer = (to) => {
  console.log(to)
  document.title = to.meta.title || to.name;
  if(to.meta.data) {
    const meta = document.createElement('meta');
    meta.content = to.meta.data.content;
    meta.name = to.meta.data.name;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
}


export function createGuard(router) {
  router.beforeEach((to, from, next) => {
    writer(to);   //调用自动添加meta
    next();
  })
}


export default routerConfig